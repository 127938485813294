import React, {Component, useState} from "react"
import {graphql} from "gatsby"

import imgPage from "../images/headers/Logos_Ale_Marine.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import style from "./singleMarine.module.scss"
import Header from "../components/header";

import Foto1 from "../images/marine/foto1.jpg"

import Rango from "../images/marine/icon_velocidad.svg"
import Eslora from "../images/marine/icon_eslora.svg"
import Manga from "../images/marine/icon_manga.svg"
import Capacidad from "../images/marine/icon_capacidad.svg"
import Img from "gatsby-image";
import {Player, ControlBar} from "video-react"
import "video-react/dist/video-react.css"
import Form from "./form";
import Foto2 from "../images/marine/foto2.jpg";

export default ({data, pageContext: {slug, video}}) => {
    const post = data.mdx
    const extraInfo =
        [
            {name: '', mail: 'info@ale.mx', phone: '55 4202 0920'},
        ];

    var [selected, setSelected] = useState('');
    var [showing, setShowing] = useState(false);

    function changeSelected(select, e) {
        setSelected(select);
        setShowing(false)
    }

    function showList() {
        setShowing(!showing)
    }

    return (

        <Layout extraInfo={extraInfo}>
            <Header LogoPage={imgPage}/>
            <SEO title="AleMarine Aerolineas Ejecutivas"/>

            <div className={style.marineCover}>
                <Img fluid={post.frontmatter.cover.childImageSharp.fluid}/>
            </div>
            <Form image={Foto2} options={['Share At Sea: Fractional Navigation', 'Princess Yachts: New Yacht']}/>

            <div className={style.marineInfo}>
                <h1>{post.frontmatter.yachts}</h1>
                <p>{post.frontmatter.descripcion}</p>
                <p>{post.frontmatter.descripcion_2}</p>
            </div>
            <div className={style.marineSpecs}>
                <h2>Especificaciones</h2>
                <div className={style.content}>
                    <div className={style.item}>
                        <div className={style.top}>
                            <p>Rango de Velocidad</p>
                            <span> </span>
                        </div>
                        <div className={style.bottom}>
                            <Rango/>
                            <p>{post.frontmatter.velocidad}</p>
                        </div>
                    </div>
                    <div className={style.item}>
                        <div className={style.top}>
                            <p>Eslora</p>
                            <span> </span>
                        </div>
                        <div className={style.bottom}>
                            <Eslora/>
                            <p>{post.frontmatter.eslora}</p>
                        </div>
                    </div>
                    <div className={style.item}>
                        <div className={style.top}>
                            <p>Manga</p>
                            <span> </span>
                        </div>
                        <div className={style.bottom}>
                            <Manga/>
                            <p>{post.frontmatter.manga}</p>
                        </div>
                    </div>
                    <div className={style.item}>
                        <div className={style.top}>
                            <p>Capacidad de agua</p>
                            <span> </span>
                        </div>
                        <div className={style.bottom}>
                            <Capacidad/>
                            <p>{post.frontmatter.capacidad}</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className={style.marineImages}>
                {data.videoYatch &&
                <>
                    <div className={style.marineVideo}>
                        <img src={Foto1} alt="AleMarine"/>
                        <PlayerMarine publicURL={data.videoYatch.publicURL}/>
                    </div>
                </>
                }
                {!data.videoYatch &&
                <img src={Foto1} alt="AleMarine"/>
                }
                <div className={style.title}>
                    <p>Profile white hull</p>
                    <span> </span>
                </div>
                <div className={style.img}>
                    <Img fluid={post.frontmatter.profile.childImageSharp.fluid}/>
                </div>
                <div className={style.title}>
                    <p>Main Deck</p>
                    <span> </span>
                </div>
                <div className={style.img}>
                    <Img fluid={post.frontmatter.main.childImageSharp.fluid}/>
                </div>
                <div className={style.title}>
                    <p>Lower Deck</p>
                    <span> </span>
                </div>
                <div className={style.img}>
                    <Img fluid={post.frontmatter.lower.childImageSharp.fluid}/>
                </div>
                <div className={style.base}>
                    <div className={style.title}>
                        <p>Puerto Base</p>
                    </div>


                    <div className={style.marineMap}>
                        <div className={showing ? `${style.titles} ${style.showing}` : style.titles}>
                            {post.frontmatter.docking.map((item) => {
                                var reg = /"(.*?)"/g, classDocking = reg.exec(item)[1],
                                    nameDocking = item.replace(/"(.*?)"/g, '');
                                classDocking = classDocking.split(" ");
                                return (
                                    <button className={classDocking.includes(selected) ? style.active : ''}
                                            onClick={(e) => changeSelected(classDocking[0], e)}>
                                        <span> </span>{nameDocking}
                                    </button>
                                )
                            })}

                            <span> </span>
                            <button className={style.responsiveButton} onClick={(e) => showList()}></button>
                        </div>
                        <div className={style.map}>
                            <Img fluid={data.mapa.childImageSharp.fluid}/>
                            {post.frontmatter.docking.map((item) => {
                                var reg = /"(.*?)"/g, classDocking = reg.exec(item)[1];
                                classDocking = classDocking.split(" ");
                                return (
                                    classDocking.map((dClass) => {
                                        return (
                                            <button onClick={(e) => changeSelected(dClass, e)}
                                                    className={classDocking.includes(selected) ? `${style[dClass]} ${style.active}` : style[dClass]}> </button>
                                        )
                                    })
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <Form image={Foto2} options={['Share At Sea: Fractional Navigation', 'Princess Yachts: New Yacht']}/>

        </Layout>
    )
}

export class PlayerMarine extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            vidStatus: false,
            player: null,
            video: props.publicURL
        };
        this.startVideo = this.startVideo.bind(this);
        this.onEnded = this.onEnded.bind(this);
    }


    startVideo() {
        this.setState({vidStatus: true})
        this.player.play();
    }

    onEnded() {
        this.setState({vidStatus: false})
    }

    render() {
        return (
            <Player
                poster={Foto1}
                onEnded={this.onEnded}
                className={this.state.vidStatus === true ? `${style.video} ${style.active}` : `${style.video}`}
                src={this.state.video}
                ref={player => {
                    this.player = player;
                }}
            >
                <ControlBar disableCompletely={false}/>
                <button className={style.button} onClick={this.startVideo}>Play Video</button>
            </Player>
        )
    }

}

export const query = graphql`
query($slug: String!, $video: String!) {
  videoYatch:file(name: {regex: $video}) {
    publicURL
  }
  mdx(frontmatter: {slug: {eq: $slug}}) {
    frontmatter {
      slug
      video
      descripcion
      descripcion_2
      title
      velocidad
      manga
      capacidad
      eslora
      yachts
      docking
      cover {
        childImageSharp {
          fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      profile {
        childImageSharp {
          fluid(maxWidth: 790) {
                ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      main {
        childImageSharp {
          fluid(maxWidth: 790) {
                ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lower {
        childImageSharp {
          fluid(maxWidth: 790) {
                ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
  mapa: file(relativePath: {eq: "marine/mapa.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}`
